<template>
    <div class="content flex wrap center vcenter">
        <div class="flex wrap center vcenter">
            <div>
                <h1>در حال بروزرسانی...</h1>
                <p>
                    در حال بروزرسانی آتیا هستیم و به زودی بازمی‌گردیم.
                </p>
                <!-- <div class="flex wrap center vcenter">
                    <button @click.prevent="this.$router.push('/')">بازگشت به صفحه اصلی</button>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    name: 'Maintenance',
}
</script>

<style scoped src="../assets/css/views/maintenance.css"></style>