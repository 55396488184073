/* eslint-disable */
import {
  createRouter,
  createWebHistory
} from 'vue-router'
const routes = [{
    path: '/',
    name: 'home',
    component: () => import( /* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: {
      title: 'آتیاکراد | سکوی تأمین مالی جمعی آتیۀ ایرانیان'
    }
  },
  {
    path: '/Atiya',
    name: 'atiya',
    component: () => import( /* webpackChunkName: "atiya" */ '../views/Atiya.vue'),
    props: true,
    meta: {
      title: 'آتیاکراد | درباره آتیا'
    }
  },
  {
    path: '/Project/:id',
    name: 'project',
    component: () => import( /* webpackChunkName: "project" */ '../views/Project.vue'),
    props: true,
    meta: {
      title: 'آتیاکراد | جزئیات طرح'
    }
  },
  {
    path: '/Projects',
    name: 'projects',
    component: () => import( /* webpackChunkName: "projects" */ '../views/Projects.vue'),
    meta: {
      title: 'آتیاکراد | طرح‌ها'
    }
  },
  {
    path: '/Contact',
    name: 'contact',
    component: () => import( /* webpackChunkName: "contact" */ '../views/Contact.vue'),
    meta: {
      title: 'آتیاکراد | تماس باما'
    }
  },
  {
    path: '/Social',
    name: 'social',
    component: () => import( /* webpackChunkName: "social" */ '../views/Social.vue'),
    meta: {
      title: 'آتیاکراد | شبکه‌های اجتماعی'
    }
  },
  {
    path: '/Newsletter',
    name: 'newsletter',
    component: () => import( /* webpackChunkName: "newsletter" */ '../views/Newsletter.vue'),
    meta: {
      title: 'آتیاکراد | خبرنامه'
    }
  },
  {
    path: '/Partnership',
    name: 'partnership',
    component: () => import( /* webpackChunkName: "partnership" */ '../views/Partnership.vue'),
    meta: {
      title: 'آتیاکراد | تقاضای همکاری'
    }
  },
  {
    path: '/Financing',
    name: 'financing',
    component: () => import( /* webpackChunkName: "financing" */ '../views/Financing.vue'),
    meta: {
      title: 'آتیاکراد | درخواست تأمین مالی'
    }
  },
  {
    path: '/Funding',
    name: 'funding',
    component: () => import( /* webpackChunkName: "funding" */ '../views/Funding.vue'),
    meta: {
      title: 'آتیاکراد | ثبت طرح و تأمین سرمایه'
    }
  },
  {
    path: '/Introduction',
    name: 'introduction',
    component: () => import( /* webpackChunkName: "introduction" */ '../views/Introduction.vue'),
    meta: {
      title: 'آتیاکراد | آشنایی با سکوی آتیا ایرانیان'
    }
  },
  {
    path: '/Investment',
    name: 'investment',
    component: () => import( /* webpackChunkName: "investment" */ '../views/Investment.vue'),
    meta: {
      title: 'آتیاکراد | ثبت نام و سرمایه گذاری'
    }
  },
  {
    path: '/News',
    name: 'news',
    component: () => import( /* webpackChunkName: "news" */ '../views/News.vue'),
    meta: {
      title: 'آتیاکراد | خبرنامه'
    }
  },
  {
    path: '/Blog/:page',
    alias: ['/Blog/:page/:category'],
    name: 'blog',
    component: () => import( /* webpackChunkName: "blog" */ '../views/Blog.vue'),
    props: true,
    meta: {
      title: 'آتیاکراد | بلاگ'
    }
  },
  {
    path: '/Post/:id',
    name: 'post',
    component: () => import( /* webpackChunkName: "post" */ '../views/Post.vue'),
    props: true,
    meta: {
      title: 'آتیاکراد | پست بلاگ'
    }
  },
  {
    path: '/Knowledge',
    name: 'knowledge',
    component: () => import( /* webpackChunkName: "knowledge" */ '../views/Knowledge.vue'),
    meta: {
      title: 'آتیاکراد | دانشنامه'
    }
  },
  {
    path: '/Authentication',
    alias: ['/Register'],
    name: 'authentication',
    component: () => import( /* webpackChunkName: "authentication" */ '../views/Authentication.vue'),
    meta: {
      title: 'آتیاکراد | احراز هویت'
    }
  },
  {
    path: '/Dashboard',
    name: 'dashboard',
    component: () => import( /* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    props: true,
    meta: {
      title: 'آتیاکراد | پرتال سرمایه گذاری',
    },
    children: [{
        path: '/Dashboard',
        name: 'root',
        component: () => import( /* webpackChunkName: "root" */ '../views/Root.vue'),
      },
      {
        path: '/Dashboard/Form/:entity_type',
        alias: ['/Dashboard/Form/:entity_type/:id'],
        name: 'form',
        component: () => import( /* webpackChunkName: "form" */ '../components/Form.vue'),
        props: true
      },
      {
        path: '/Dashboard/List/:entity_type/:page/:size',
        name: 'list',
        component: () => import( /* webpackChunkName: "list" */ '../components/List.vue'),
        props: true
      },
      {
        path: '/Dashboard/Receipt/:id',
        name: 'receipt',
        component: () => import( /* webpackChunkName: "receipt" */ '../components/Receipt.vue'),
        props: true
      },
      {
        path: '/Dashboard/SIP',
        name: 'sip',
        component: () => import( /* webpackChunkName: "sip" */ '../components/Phone/SIP.vue')
      },
      {
        path: '/Dashboard/Ticket',
        name: 'ticket',
        component: () => import( /* webpackChunkName: "ticket" */ '../views/Ticket.vue'),
      },
      {
        path: '/Dashboard/Investment/Deposit',
        name: 'investments',
        component: () => import( /* webpackChunkName: "investments" */ '../views/Investments.vue'),
        props: true
      },
      {
        path: '/Dashboard/Offer/:status',
        name: 'offers',
        component: () => import( /* webpackChunkName: "offers" */ '../views/Offers.vue'),
        props: true
      },
      {
        path: '/Dashboard/Calculator',
        name: 'calculator',
        component: () => import( /* webpackChunkName: "calculator" */ '../views/Calculator.vue')
      },
      {
        path: '/Dashboard/Inquiry/Internal',
        name: 'internalinquiry',
        component: () => import( /* webpackChunkName: "internalinquiry" */ '../views/InternalInquiry.vue')
      },
      {
        path: '/Dashboard/Inquiry/External',
        name: 'externalinquiry',
        component: () => import( /* webpackChunkName: "externalinquiry" */ '../views/ExternalInquiry.vue')
      },
      {
        path: '/Dashboard/Inquiry/External/Warranty',
        name: 'warrantyinquiry',
        component: () => import( /* webpackChunkName: "warrantyinquiry" */ '../views/WarrantyInquiry.vue')
      },
      {
        path: '/Dashboard/Inquiry/External/Sejam',
        name: 'sejaminquiry',
        component: () => import( /* webpackChunkName: "sejaminquiry" */ '../views/SejamInquiry.vue')
      },
      {
        path: '/Dashboard/Marketing',
        name: 'marketing',
        component: () => import( /* webpackChunkName: "marketing" */ '../views/Marketing.vue')
      },
      {
        path: '/Dashboard/:pathMatch(.*)*',
        name: 'maintenance',
        component: () => import( /* webpackChunkName: "maintenance" */ '../components/Maintenance.vue')
      }
    ]
  },
  {
    path: '/ComingSoon',
    name: 'comingsoon',
    component: () => import( /* webpackChunkName: "comingsoon" */ '../views/Maintenance.vue'),
    meta: {
      title: 'آتیاکراد | درحال بروزرسانی'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notfound',
    component: () => import( /* webpackChunkName: "notfound" */ '../components/NotFound.vue'),
    meta: {
      title: 'آتیاکراد | صفحه یافت نشد'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      const element = document.querySelector(to.hash)
      if (element) {
        return {
          el: to.hash,
          behavior: 'smooth'
        }
      }
    }
    return {
      top: 0
    }
  }
})

export default router
