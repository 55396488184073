/* eslint-disable */
import {
  notify
} from '@kyvg/vue3-notification'

function error(obj) {
  let text = ''
  switch (obj.code) {
    case 401:
      text = 'دسترسی غیرمجاز!'
      break
    case 1000:
      text = `مقدار "${obj.text}" ضروری یا نامعتبر است!`
      break
    case 1001:
      text = `پسوند فایل "${obj.text}" غیر مجاز است!`
      break
    case 1002:
      text = `بارگذاری فایل "${obj.text}" به علت حجم غیرمجاز انجام نشد!`
      break
    case 1003:
      text = `بارگذاری فایل "${obj.text}" به علت عبور از حجم مجاز آپلود انجام نشد! `
      break
    case 1004:
      text = `بارگذاری فایل "${obj.text}" به علت محدودیت تعداد فایل مجاز انجام نشد! `
      break
    case 1005:
      text = `بارگذاری فایل "${obj.text}" با خطا مواجه شد!`
      break
    case 4003:
      if (obj.referr === 'login')
        text = `دسترسی به حساب کاربری از طریق آدرس اینترنتی ${obj.message} امکان پذیر نیست!`
      else
        text = 'دسترسی به منابع مسدود گردید!'
      break
    case 4004:
      if (obj.referr === 'login')
        text = 'شما هنوز ثبت نام نکرده اید، از قسمت ایجاد حساب کاربری، افتتاح حساب نمایید!'
      else
        text = 'مورد جست‌جو یافت نشد!'
      break
    case 4005:
      if (obj.referr === 'register') {
        if (obj.message === 'username')
          text = 'کاربر با شناسه ملی ذیل، قبلا ثبت نام شده است؛ لطفا از قسمت ورود، وارد حساب کاربری خود شوید!'
        else if (obj.message === 'mobile')
          text = 'کاربر با شماره همراه ذیل از پیش ثبت نام شده است!'
      } else if (obj.referr === 'newsletter')
        text = 'شما قبلا در خبرنامه آتیاکراد عضو شدید!'
      else
        text = `تعارض داده در "${obj.text}" رخ‌داد!`
      break
    case 4008:
      text = 'کد تائید نامعتبر است!'
      break
    case 4009:
      text = 'کد تائید منقصی گردید!'
      break
    case 4016:
      if (obj.referr === '_2fa')
        text = 'گذرواژه نامعتبر است!'
      else
        text = 'عدم تطابق مالکیت شناسه ملی و شماره همراه'
      break
    case 4017:
      text = 'تغییراتی جهت ثبت شناسایی نشد!'
      break
    case 4018:
      text = 'ثبت و تغییرات در داده‌ها به هنگام حالت تعمیر و نگهداری سرور امکان پذیر نیست!'
      break
    case 5000:
      text = obj.text
      break
    default:
      text = `خطای ${obj.code} رخ‌داد`
  }
  toast('خطا', text, 'error')
}

function toast(title, text, type = 'info', id = '', duration = 6000) {
  notify({
    id: id,
    title: title,
    text: text,
    type: type,
    duration: duration,
  })
}

function close(id) {
  notify.close(id)
}

export default {
  error,
  toast,
  close
}
