/* eslint-disable */
import cookies from 'vue-cookies'
import util from './util'

import {
  getMessaging,
  deleteToken
} from "firebase/messaging"

function session() {
  const ua = window.navigator.userAgent
  return {
    data: {
      device: 'WebKit',
      os: ua.includes('Windows') ?
        'Windows' : ua.includes('Linux') ?
        'Linux' : ua.includes('mac') ?
        'macOS' : 'Other',
      agent: `Web ${ua}`
    }
  }
}

function identify(routes) {
  if (!cookies.get('identifier') && util.validate(routes.filter(path => {
      return window.location.pathname.toLowerCase().includes(path)
    })))
    logout(routes)
}

function logout(routes = []) {
  if (window.location.pathname !== '/Authentication') {
    localStorage.removeItem('modules')
  }

  ['identifier', 'uid', 'gid', 'rid', 'grade', 'username', 'mobile', 'display_name', 'referral_code', 'balance', 'inprocess', 'fcm']
  .forEach((cookie) => cookies.remove(cookie))

  const messaging = getMessaging()
  deleteToken(messaging).then(() => {}).catch((err) => {})

  if (util.validate(routes.filter(path => {
      return window.location.pathname.toLowerCase().includes(path)
    })))
    window.location.href = '/Authentication'
}

export default {
  session,
  identify,
  logout
}
